export const DOZEN = 12;

export const RestrictedChannel = {
    Shop: "shop",
    Combo: "combo",
};

export const UnitType = {
    Gram: "gram",
    Piece: "piece",
    PieceAsDozen: "pieceAsDozen",
};

export const ProductType = {
    Utensil: "Ustensile",
    Fish: "Poisson",
    Crustacean: "Crustacé",
    Cephalopod: "Céphalopode",
    Shell: "Coquillage",
    Seaweed: "Algue",
    Herb: "Herbe",
    Tool: "Ustensile",
    Misc: "Divers",
    Transformed: "Transformation",
    Mixed: "Mixte",
};

export const PauseReasons = {
    "holidays-no-pr": "Je pars en vacances / déplacement, dans un lieu sans point relais",
    "holidays-no-delivery": "Je pars en vacances / déplacement, je ne veux pas me faire livrer",
    "too-busy": "Trop occupé en ce moment, pas dispo pour récupérer / cuisiner mon Casier",
    "relay-closed-permanently": "Mon point relais a fermé définitivement, j’en cherche un nouveau",
    "relay-closed-temporarily": "Mon point relais a fermé temporairement, je reprends à son retour",
    "freezer-full": "Mon congel est plein, je reprends plus tard",
    "moving": "Je déménage et je suspends le temps de trouver un point relais",
    "prefer-shop-purchases": 'Je préfère faire des achats "à la carte" pour le moment',
    "waiting-seasonal-product": "J’attends la saison d’un produit en particulier pour reprendre",
    "disappointed": "Je suis déçu par Poiscaille, j’hésite à me désabonner",
};

export const relayCouponTypesAndLabels = [
    ["relay_acquisition", "Point relais (acquisition)"],
    ["relay_incentive", "Point relais (incentive)"],
    ["relay_employee", "Point relais (employé)"],
    ["relay_gift", "Point relais (cadeau)"],
    ["relay_onboarding", "Point relais (onboarding)"],
    ["relay_legacy", "Point relais (legacy)"],
];

export const couponTypesMap = new Map([
    ["truck", "Camions"],
    ["giftcard", "Carte cadeau"],
    ["misc", "Divers"],
    ["dynamization", "Dynamisation"],
    ["facebook", "Facebook"],
    ["front", "Front"],
    ["affiliation", "Affiliation"],
    ["partner", "Influenceurs & partenaires"],
    ["lottery", "Jeux concours"],
    ["mailing", "Mailing"],
    ["offline", "Offline"],
    ["online", "Online"],
    ["godfather", "Parrainage"],
    ...relayCouponTypesAndLabels,
    ["media", "Presse"],
    ["contractor", "Presta"],
    ["stripe", "Remises Stripe"],
    ["comeback", "Retours"],
    ["fair", "Salons"],
    ["customer_service", "Service client"],
    ["staff", "Staff"],
    ["veepee", "Veepee"],
    ["wholesale", "Wholesale"],
]);
